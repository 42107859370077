[data-v-7c875117] .el-input__inner {
  font-size: 12px;
}
.bank-item-container[data-v-7c875117] {
  margin-bottom: 10px;
  border: 1px solid #b9b9b9;
  border-bottom: 0 !important;
}
.bank-item-header[data-v-7c875117] {
  width: 100%;
  background: var(--themeColorLeftMenu, #ecf7f9);
  box-sizing: border-box;
  padding-left: 15px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  position: relative;
  border-bottom: 1px solid #b9b9b9;
}
.bank-item-header > div[data-v-7c875117] {
  margin-right: 30px;
  display: inline-block;
}
.bank-item-header .item-input[data-v-7c875117] {
  position: absolute;
  left: 5px;
  zoom: 110%;
  top: 50%;
  transform: translateY(-50%);
}
.dfyhm[data-v-7c875117] {
  cursor: pointer;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
  font-size: 12px;
}
.bank-item-header-caozuo[data-v-7c875117] {
  float: right;
}
.bank-item-header-caozuo i[data-v-7c875117] {
  margin: 0 5px;
  font-size: 17px;
  line-height: 20px;
}
.item_box[data-v-7c875117] {
  width: 100%;
  font-size: 12px;
}
.item_box tr[data-v-7c875117] {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.item_box tr td[data-v-7c875117] {
  line-height: 36px;
  border-color: #b9b9b9 !important;
  border-bottom: 1px solid #b9b9b9;
  border-right: 1px solid #b9b9b9;
}
.item_box tr td[data-v-7c875117]:last-child {
  border-right: none;
}
.top_right_btns[data-v-7c875117] {
  float: right;
  text-align: center;
}
.top_right_btns i[data-v-7c875117] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
  line-height: 24px;
}
.bank-table-header[data-v-7c875117] {
  background: var(--themeColor, #17a2b8);
  position: relative;
  border: 1px solid #b9b9b9;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 13px;
}
.bank-table-header > div[data-v-7c875117] {
  border-right: 1px solid #b9b9b9;
  text-align: center;
}
.bank-table-header > div[data-v-7c875117]:last-child {
  border-right: 0;
}
.bank-table-header input[data-v-7c875117] {
  position: absolute;
  zoom: 120%;
  left: 5px;
  top: 45%;
  transform: translateY(-50%) !important;
}
.table_height[data-v-7c875117] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.ccyc[data-v-7c875117] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #212529;
}
.top_btns[data-v-7c875117] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.opera_icon[data-v-7c875117] {
  cursor: pointer;
  font-size: 14px;
  top: 2px;
}
.hint[data-v-7c875117] {
  position: absolute;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  top: 12px;
  left: 233px;
  z-index: 2;
}
.check[data-v-7c875117] {
  position: absolute;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  top: 12px;
  left: 530px;
  z-index: 2;
  cursor: pointer;
}
.content[data-v-7c875117] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-7c875117] {
  padding: 0;
  margin: 0;
}
.content td[data-v-7c875117] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 28px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
}
.content .center[data-v-7c875117] {
  text-align: center;
}
.choose-business-type-box[data-v-7c875117] {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 3px;
}
.choose-business-type-box .block[data-v-7c875117] {
  width: 100%;
}