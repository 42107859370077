.piliang-container[data-v-205d9162] {
  float: right;
}
.bank-table-header[data-v-205d9162] {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
}
.bank-table-header > div[data-v-205d9162] {
  float: left;
  border-right: 1px solid #c2c2c2;
  text-align: center;
  box-sizing: border-box;
  width: 16.6666666667%;
}
.bank-table-header > div[data-v-205d9162]:last-child {
  border-right: 0;
}
.bank-table-header input[data-v-205d9162] {
  position: absolute;
  zoom: 120%;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) !important;
}
.top_right_btns[data-v-205d9162] {
  float: right;
  margin-right: 15px;
}
.top_right_btns i[data-v-205d9162] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.pagination[data-v-205d9162] {
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
.top_btns[data-v-205d9162] {
  margin-bottom: 10px;
}