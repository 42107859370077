.cascader-panel-container[data-v-0ad3560a] {
  position: fixed;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
.cascader-panel-container .cascader-menu[data-v-0ad3560a] {
  width: 204px;
  height: 206px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
.cascader-panel-container .cascader-menu .empty[data-v-0ad3560a] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #a8abb2;
  font-size: 14px;
}
.cascader-panel-container .cascader-menu.cascader-menu-next[data-v-0ad3560a] {
  position: fixed;
  width: 400px;
}
.cascader-panel-container .cascader-menu .list-item[data-v-0ad3560a] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.cascader-panel-container .cascader-menu .list-item.active[data-v-0ad3560a] {
  font-weight: 700;
  color: #409eff;
}
.cascader-panel-container .cascader-menu .list-item .item-name[data-v-0ad3560a] {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cascader-panel-container .cascader-menu .list-item.disabled[data-v-0ad3560a] {
  opacity: 0.7;
  cursor: not-allowed;
}