.piliang-container[data-v-26731ae4] {
  float: right;
}
.top_right_btns[data-v-26731ae4] {
  float: right;
  margin-right: 15px;
}
.top_right_btns i[data-v-26731ae4] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.top_btns[data-v-26731ae4] {
  margin-bottom: 10px;
}