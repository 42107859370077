.virtual-cascader-container[data-v-0446a83e] {
  width: 100%;
}
.virtual-cascader-container input[data-v-0446a83e] {
  outline: none;
}
.virtual-cascader-container .input-box[data-v-0446a83e] {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1px 7px;
  border: 1px solid #b9b9b9;
  cursor: pointer;
}
.virtual-cascader-container .input-box .input[data-v-0446a83e] {
  display: block;
  flex: 1;
  height: 20px;
  padding: 0;
  font-size: 12px;
  color: #606266;
}